<template>
    <div>
        <button
            class="back"
            @click="$router.go(-1)"
            :class="{ hidden: inAttachmentPage }"
        >
            <i class="icon-leftarrow"></i>
            <span>返回</span>
        </button>
        <img
            :src="doctorAvatar"
            class="doctor-avatar"
            :class="{ hidden: inAttachmentPage }"
        />
        <chat
            :roomId="roomId"
            :isMsg="true"
            @openAttachment="inAttachmentPage = true"
        ></chat>
        <attachment
            :active="inAttachmentPage"
            :caseId="caseId"
            @close="inAttachmentPage = false"
        ></attachment>
    </div>
</template>

<script>
import Attachment from "./chatroomComponents/attachment.vue";
import Chat from "./chatroomComponents/chat.vue";
import { mapState } from "vuex";
import clinicMixin from "./chatroomMixins/clinicMixin";
import { chatroomsDetail, chatroomslogin } from "@/api";
export default {
    mixins: [clinicMixin],
    components: {
        Chat,
        Attachment,
    },
    data: function () {
        return {
            inAttachmentPage: false,
            type: "text",
        };
    },
    computed: {
        ...mapState({
            userSig: (state) => state.user.userSig,
            userID: (state) => state.user.userId,
        }),
    },
    methods: {
        chatroomsDetail,
        chatroomslogin,
    },
    created: function () {
        this.getChatroomDetail(this.$route.params.roomNumber).then((res) => {
            let roomData = res.data;
            this.setChatroomData(roomData);
            // TODO: 后续可能还要加入诊所职员的id
            this.tim.receivedAccount = [roomData.doctor_userId];
            if (!this.isChatroomClosed) {
                this.prepareSDK();
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.back {
    position: relative;
    left: 0;
    z-index: 1000;
    color: $green-300;
    font-size: 24px;
    font-weight: $semi-bold;
    background-color: transparent;
    padding: 24px 8px;
    transition: all 0.3s ease;
    transition-delay: 0.2s;
    &.hidden {
        left: -100%;
        transition-delay: 0s;
    }
}
.doctor-avatar {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 900;
    object-fit: cover;
    object-position: top;
    transform-origin: top center;
    transition: all 0.3s ease;
    &.hidden {
        transform: scaleY(0);
    }
}
</style>